<style scoped>
.home {
  padding: 0 15px;
}
.wordContent {
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  justify-content: flex-start;
  height: 18%;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.content {
  margin-top: 10px;
  text-align: start;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2;
  color: #666666;
  opacity: 1;
}
.about {
  width: 100%;
  font-size: 50px;
  font-family: Arial;
  font-weight: bold;
  color: #5e9f46;
  letter-spacing: 5px;
  opacity: 0.1;
}
.div-img {
  width: 100%;
}
.div-img img {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="home">
    <div class="wordContent">
      <div class="about">About</div>
      <div class="title">武汉欣民富科技有限公司</div>
      <div class="content">
        <div>
          武汉欣民富科技有限公司成立于2021年09月02日，经营范围包括许可项目：货物进出口；技术进出口；进出口代理（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准...
        </div>
        <br />
        <div style="margin-top: 10%">
          <span style="display: inline-block">联系地址：武汉东湖新技术开发区光谷大道</span><br />
          <span style="display: inline-block">联系电话：13986277507</span><br />
          <span style="display: inline-block">网址：www.xinmingfu.com</span>
        </div>
      </div>
    </div>
    <div class="div-img">
      <img src="./../assets/map.png" />
    </div>
  </div>
</template>
<script>
export default {};
</script>
