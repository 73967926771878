<template>
  <div id="app">
    <AppPage v-if="isApp"></AppPage>
    <HomePage v-else></HomePage>
    <!-- <HelloWorld></HelloWorld> -->
  </div>
</template>

<script>
import HomePage from './components/homePage.vue';
import AppPage from './components/appPage.vue'

export default {
  name: 'App',
  components: {
    HomePage,
    AppPage
  },
  data() {
    return {
      pageWidth: 1920
    }
  },
  created() {
    this.pageWidth = window.innerWidth;
    window.onresize = () => {
      this.pageWidth = window.innerWidth;
    }
  },
  computed: {
    isApp() {
      return this.pageWidth < 800
    }
  }
}
</script>

<style>
*{
  margin:0;
  padding:0;
}
html,body,#app{
  width: 100%;
  height: 100%;
  background-color: #f4fefb!important;
}
.width-100 {
  width: 100%;
}
</style>
