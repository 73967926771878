<template>
  <div class="home">
    <div class="main">
      <div class="div-img">
        <img src="./../assets/map.png" />
      </div>
      <div class="wordContent">
        <div class="about">About</div>
        <div class="title">武汉欣民富科技有限公司</div>
        <div class="content">
          <div>
            武汉欣民富科技有限公司成立于2021年09月02日，经营范围包括许可项目：货物进出口；技术进出口；进出口代理（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准...
          </div>
          <br />
          <div style="margin-top: 10%">
            <span style="display: inline-block">联系地址：武汉东湖新技术开发区光谷大道</span><br />
            <span style="display: inline-block">联系电话：13986277507</span><br />
            <span style="display: inline-block">网址：www.xinmingfu.com</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.about {
  width: 230px;
  height: 78px;
  font-size: 70px;
  font-family: Arial;
  font-weight: bold;
  line-height: 111px;
  color: #5e9f46;
  letter-spacing: 17px;
  opacity: 0.1;
}
.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 33px;
  background-image: url(./../assets/bk.png);
}
.div-img {
  flex: 1;
  margin-top: 2%;
}
.div-img img {
  width: 100%;
  height: 100%;
}
.guard {
  width: 27%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  justify-content: space-between;
}
.main {
  display: flex;
  padding-top: 6%;
  width: 81%;
  height: 100%;
}
.guardTotal {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
  letter-spacing: 2px;
  opacity: 1;
}
.peopleNum {
  display: flex;
  height: 100%;
  margin-top: 28px;
}
.Count {
  border: 1px solid rgba(94, 159, 70, 0.2);
  border-radius: 6px;
  line-height: 99px;
}
.Count span {
  color: #5e9f46;
  font-size: 29px;
  font-weight: bolder;
  letter-spacing: 11px;
  font-family: Futura;
}
.unit {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 113px;
  color: #999999;
  letter-spacing: 2px;
  opacity: 1;
  padding-left: 12px;
}
.wordContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 5.5%;
}
.title {
  display: flex;
  justify-content: flex-start;
  height: 18%;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.content {
  margin-top: -6px;
  text-align: start;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #666666;
  opacity: 1;
}
.num {
  font-size: 60px;
  font-family: Futura;
  height: 100%;
  line-height: 113px;
  color: #5e9f46;
  opacity: 1;
}
</style>
