<style lang="less" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
a{
  color: #999;
}
.companyName {
  width: 100%;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  padding: 10px 0;
  text-align: center;
}
.tabs-ul {
  display: flex;
  width: 100%;
  position: relative;
  padding: 0 20px;
  li {
    flex: 1;
    text-align: center;
    font-size: 16px;
    padding: 5px 0;
    color: #999;
    cursor: pointer;
    &.active {
      color: #5e9f46;
    }
  }
  .line {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
    background-color: #5e9f46;
    transition: left 300ms ease-in-out;
  }
}
.footer {
  width: 100%;
  background: #ffffff;
  border-radius: 0px;
  z-index: 999;
}
.footer-text {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  color: #999999;
  border-top: 1px solid rgba(204, 204, 204, 0.3);
  opacity: 1;
}
.main-container {
  flex: 1;
  height: 0;
  overflow-y: auto;
}
.title {
  display: flex;
  justify-content: flex-start;
  height: 18%;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.download-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-top: 20px;
  li {
    margin: 0 10px;
    text-align: center;
    h5 {
      font-size: 16px;
      font-weight: normal;
      color: #5e9f46;
    }
    p {
      font-weight: bold;
    }
  }
  img {
    width: 100%;
  }
}
</style>

<template>
  <div id="main" class="wrapper">
    <!-- tab-swiper -->
    <div class="tab-swiper container">
      <div class="companyName">武汉欣民富科技有限公司</div>
      <!-- <ul class="tabs-ul">
        <li v-for="item in list" :key="item.id" ref="liRefs" :class="{ active: activeId === item.id }" @click="changeTab(item)">
          {{ item.name }}
        </li>
        <div class="line" :style="{ width: `${liWidth}px`, left: lineLeft }"></div>
      </ul> -->
    </div>
    <div class="main-container">
      <AppHome></AppHome>
      <AppServe></AppServe>
      <AppAbout></AppAbout>
      <ul class="download-list ">
        <li>
          <h5>孝心家管家App</h5>
          <img  src="@/assets/qrcode_02.png" alt="qrcode" >
          <p>Andirod</p>
        </li>
         <li>
          <h5>孝心家App</h5>
          <img src="@/assets/qrcode_01.png" alt="qrcode" >
          <p>Andirod</p>
        </li>
      </ul>
    </div>
    <div class="footer">
      <div class="footer-text">Copyright © 武汉欣民富科技有限公司 All Rights Reserved 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">{{RecordNumber}}</a></div>
    </div>
  </div>
</template>
<script>
import { urlMap } from "@/enum/cacheEnum.js";
import AppHome from "./appHome.vue";
import AppServe from "./appServe.vue";
import AppAbout from "./appAbout.vue";
export default {
  name: "app-page",
  components: {
    AppHome,
    AppServe,
    AppAbout,
  },
  data() {
    return {
      urlMap,
      activeId: 1,
      liWidth: 90,
      list: [
        { id: 1, name: "首页" },
        { id: 2, name: "产品中心" },
        { id: 3, name: "关于我们" },
      ],
      RecordNumber:'鄂ICP备2021018118号'
    };
  },
   created(){
    this.RecordNumber = urlMap[window.location.href];
  },
  mounted() {
    
  },
  methods: {
    changeTab(item) {
      this.activeId = item.id;
    },
  },
  computed: {
    lineLeft() {
      return `${(this.activeId - 1) * this.liWidth + 20}px`;
    },
  },
};
</script>
