<style lang="less" scoped>
.home {
  padding: 0 15px;
  margin-top: 30px;
}
.filial,
.filial img,
.bg-img {
  width: 100%;
}

.wordContent {
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  justify-content: flex-start;
  height: 18%;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.content {
  margin-top: 10px;
  text-align: start;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2;
  color: #666666;
  opacity: 1;
}
.ImgContent {
  margin-top: 20px;
  width: 100%;
  img {
    width: 100%;
  }
}
</style>

<template>
  <div class="home">
    <div class="header">
      <Tabs type="card" @on-click="tabClick">
        <TabPane label="孝心家APP"></TabPane>
        <TabPane label="服务商APP"></TabPane>
        <TabPane label="孝心家平台系统"></TabPane>
        <TabPane label="居家大屏可视化"></TabPane>
      </Tabs>
    </div>
    <div class="main">
      <div class="filial" v-if="isIndex == 0">
        <div class="lunbo">
          <el-carousel :interval="4000" type="card">
            <el-carousel-item v-for="(item, index) in ImgList" :key="index">
              <img :src="item.imgUrl" class="bg-img" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="wordContent">
          <span class="title">孝心家APP</span>
          <div class="content">
            <span
              >获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，查看硬件设备详</span
            >
            <div class="ImgContent">
              <img src="./../assets/text.png" class="bg-img1" />
            </div>
          </div>
        </div>
      </div>
      <div class="filial" v-if="isIndex == 1">
        <div class="lunbo">
          <img src="./../assets/phoneGroup.png" />
        </div>
        <div class="wordContent">
          <span class="title">服务商APP</span>
          <div class="content">
            <span
              >获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。</span
            ><br />
            <span
              >获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。</span
            >
          </div>
        </div>
      </div>
      <div class="filial" v-if="isIndex == 2">
        <div class="lunbo">
          <img src="./../assets/mac.png" />
        </div>
        <div class="wordContent">
          <span class="title">孝心家平台系统</span>
          <div class="content">
            <span
              >获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。</span
            ><br />
          </div>
        </div>
      </div>
      <div class="filial" v-if="isIndex == 3">
        <div class="lunbo">
          <img src="./../assets/daping.png" />
        </div>
        <div class="wordContent">
          <span class="title">居家养老大屏可视化</span>
          <div class="content">
            <span
              >获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>

export default {
  components: {
    
  },
  data() {
    return {
      isShow: false,
      isIndex: 0,
      ImgList: [
        {
          imgUrl: require("./../assets/1.png"),
        },
        {
          imgUrl: require("./../assets/2.png"),
        },
        {
          imgUrl: require("./../assets/3.png"),
        },
      ],
    };
  },
  methods: {
    tabClick(val) {
      if (val == 0) {
        this.isIndex = 0;
      } else if (val == 1) {
        this.isIndex = 1;
      } else if (val == 2) {
        this.isIndex = 2;
      } else if (val == 3) {
        this.isIndex = 3;
      }
    },
  },
};
</script>
