<template>
  <div class="filial">
    <div class="lunbo">
      <img src="./../assets/daping.png"/>
    </div>
    <div class="wordContent">
        <span class="title">居家养老大屏可视化</span>
        <div class="content">
          <span
            >获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。</span
          >
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: "home-screen",
  components: {},
  data() {
    return {
      ImgList: [
        {
          imgUrl: require("./../assets/1.png"),
        },
        {
          imgUrl: require("./../assets/2.png"),
        },
        {
          imgUrl: require("./../assets/3.png"),
        },
      ],
    };
  },
};
</script>
<style scoped>
.filial {
  background-image: url(./../assets/bk1.png);
  display: flex;
  width: 100%;
  height: 100%;
}
.lunbo{
  flex:1;
  width:0;
  height: 100%;
  margin-top: -3%;
}
.lunbo img{
  width: 100%;
  height: 100%;
}
.bg-img{
  width: 313px;
  height: 518px;
}
.filial >>> .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.filial >>> .el-carousel__item--card{
  width: 62%;
}
.wordContent {
  display: flex;
  flex-direction: column;
  margin-left: 5.5%;
  flex:1;
  width: 0;
  padding-top: 2%;
}
.title {
  display: flex;
  justify-content: flex-start;
  font-size: 48px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.content {
  margin-top: 22px;
  text-align: start;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #666666;
  opacity: 1;
}
.ImgContent{
  margin-top: 48px;
}
</style>
