<template>
  <div class="filial">
    <div class="lunbo">
      <el-carousel :interval="4000" type="card" height="553px">
        <el-carousel-item v-for="(item, index) in ImgList" :key="index">
          <img :src="item.imgUrl" class="bg-img"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="wordContent">
        <span class="title">孝心家APP</span>
        <div class="content">
          <span
            >获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。获取硬件信息数据，控制智能设备，及时看护老人身体情况、心率、睡眠、血压等。可下单申请机构人员上门服务老人，编辑服务信息，查看硬件设备详情。获取硬件信息数据，控制智能设备，查看硬件设备详</span
          >
          <div class="ImgContent">
            <img src="./../assets/text.png" class="bg-img1"/>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: "filial-home",
  components: {},
  data() {
    return {
      ImgList: [
        {
          imgUrl: require("./../assets/1.png"),
        },
        {
          imgUrl: require("./../assets/2.png"),
        },
        {
          imgUrl: require("./../assets/3.png"),
        },
      ],
    };
  },
};
</script>
<style scoped>
.filial {
  background-image: url(./../assets/bk1.png);
  display: flex;
  width: 100%;
  height: 100%;
}
.lunbo{
  width: 50%;
  height: 83%;
}
.bg-img{
  width: 79%;
  height: 98%;
}
.bg-img1{
  width: 68%;
  max-width: 100%;
}

.filial >>> .el-carousel__indicators--outside button{
  background-color:rgba(94, 159, 70, 1)
}
.filial >>> .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.filial >>> .el-carousel__item--card{
  width: 62%;
}
.wordContent {
  display: flex;
  flex-direction: column;
  width: 77%;
  margin-left: 9.5%;
}
.title {
  display: flex;
  justify-content: flex-start;
      height: 18%;

  font-size: 48px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.content {
  margin-top: -6px;
  text-align: start;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #666666;
  opacity: 1;
}
.ImgContent{
  margin-top: 48px;
  width: 100%;
  max-width: 100%;
}
</style>
