<template>
  <div class="home">
    <div class="header">
      <div class="guard">
        <div class="guardTotal">守护人总数</div>
        <div class="peopleNum">
          <div class="Count">
            <div class="num">21156</div>
          </div>
          <div class="unit">人</div>
        </div>
      </div>
      <div class="guard">
        <div class="guardTotal">服务商总数</div>
        <div class="peopleNum">
          <div class="Count">
            <div class="num">11556</div>
          </div>
          <div class="unit">人</div>
        </div>
      </div>
      <div class="guard">
        <div class="guardTotal">安装设备总数</div>
        <div class="peopleNum">
          <div class="Count">
            <div class="num">32156</div>
          </div>
          <div class="unit">人</div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="div-img">
        <img src="./../assets/picture.png" />
      </div>
      <div class="wordContent">
        <div class="title">驱动业务的智慧养老系统</div>
        <div class="content">
          <div>
            老人通过用户端实现足不出户享受家政、康复、生活照料、居家适老化改造等服务;而且老人家属也能通过平台与老人互动，了解老人各种情况，给以老人更多的陪伴与关爱。
          </div>
          <div>
            老人通过用户端实现足不出户享受家政、康复、生活照料、居家适老化改造等服务;而且老人家属也能通过平台与老人互动，了解老人各种情况，给以老人更多的陪伴与关爱。康复、生活照料、居家适老化改造等服务;而且老人家属也能通过平台与老人互动，
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./../assets/style/index.css";
export default {};
</script>
<style scoped>
.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 33px;
  background-image: url(./../assets/bk.png);
}
.div-img {
  flex: 1;
}
.div-img img {
  width: 100%;
  height: 100%;
}
.guard {
  width: 27%;
  height: 100%;
}
.header {
  display: flex;
  width: 63%;
  justify-content: space-between;
}
.main {
  display: flex;
  padding-top: 6%;
  width: 79%;
}
.guardTotal {
  display: flex;
  margin-left: 13%;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
  letter-spacing: 2px;
  opacity: 1;
}
.peopleNum {
  display: flex;
  height: 100%;
  margin-top: 4%;
}
.Count {
  border: 1px solid rgba(94, 159, 70, 0.2);
  border-radius: 6px;
  line-height: 99px;
}
.Count span {
  color: #5e9f46;
  font-size: 29px;
  font-weight: bolder;
  letter-spacing: 11px;
  font-family: Futura;
}
.unit {
  font-size: 3vh;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 86px;
  color: #999999;
  letter-spacing: 2px;
  opacity: 1;
  padding-left: 12px;
}
.wordContent {
  display: flex;
  flex-direction: column;
  /* width: 77%; */
  flex: 2;
  margin-left: 5.5%;
}
.title {
  display: flex;
  justify-content: flex-start;
  height: 18%;
  font-size: 48px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.content {
  margin-top: 24px;
  text-align: start;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #666666;
  opacity: 1;
}
.num {
  font-size: 3vw;
  font-family: "Futura";
  /* width: 100%; */
  height: 100%;
  /* overflow: hidden; */
  line-height: 86px;
  color: #5e9f46;
  opacity: 1;
  padding: 2px 36px;
}
</style>
