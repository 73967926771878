<template>
  <div id="main">
    <!-- tab-swiper -->
    <div class="tab-swiper container">
      <div class="companyName">武汉欣民富科技有限公司</div>
      <div style="position: relative;">
         <div class="downLoad1">
        <div class="downLoad" @mouseover="chufa()" @mouseout="outChufa()">下载App</div>
        <div class="dropdown flex-column-box" v-if="isShow">
          <div class="scandownLoad">——&nbsp;&nbsp;扫码下载&nbsp;&nbsp;——</div>
          <div class="scanCode flex-box">
            <div class="xiaoxinjiaServer">
              <div class="xiao-Word">孝心家管家App</div>
              <img src="./../assets/qrcode_02.png" class="bg-img" />
              <p>Andirod</p>
            </div>
            <div class="xiaoxinjiaApp">
              <div class="xiao-Word">孝心家APP</div>
              <img src="./../assets/qrcode_01.png" class="bg-img" />
              <p>Andirod</p>
            </div>
          </div>
        </div>
      </div>
      <Tabs @on-click="tabClick" :value="changeVal">
        <TabPane label="首页" name="0"></TabPane>
        <TabPane label="产品中心" name="1"></TabPane>
        <TabPane label="关于我们" name="2"></TabPane>
      </Tabs>
      </div>
     
    </div>
    <div class="main-container">
      <swiperView ref="swiperRef" @onChange="change"></swiperView>
    </div>
    <div class="footer">
      <div class="footer-text">Copyright © 武汉欣民富科技有限公司 All Rights Reserved 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">{{RecordNumber}}</a></div>
    </div>
  </div>
</template>
<script>
import { urlMap } from "@/enum/cacheEnum.js";
import swiperView from "./swiperView.vue";
export default {
  name: "home-page",
  components: {
    swiperView,
  },
  data() {
    return {
      urlMap,
      changeVal: 0,
      isShow: false,
      RecordNumber:'鄂ICP备2021018118号'
    };
  },
  created(){
    this.RecordNumber = urlMap[window.location.href];
  },
  methods: {
    tabClick(val) {
      if (val == "0") {
        this.$refs.swiperRef.isIndex = val;
      } else if (val == "1") {
        this.$refs.swiperRef.isIndex = val;
      } else if (val == "2") {
        this.$refs.swiperRef.isIndex = val;
      }
    },
    change(val) {
      this.changeVal = val + "";
    },
    chufa() {
      this.isShow = true;
    },
    outChufa() {
      this.isShow = false;
    },
  },
};
</script>
<style scoped>
#main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f4fefb;
}
.main-container {
  width: 100%;
  height: 90%;
}
.downLoad {
  border: 1px solid #7ba271;
  color: #7ba271;
  border-radius: 21px;
  padding: 7px 15px;
}
.downLoad1 {
  position: absolute;
  right: 8%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
.dropdown {
  position: absolute;
  bottom: -10px;
  right: 0;
  transform: translate(0, 100%);
  width: 360px;
  height: 223px;
  border-radius: 15px;
  box-shadow: 0 0 5px #ccc;
}
.flex-1 {
  flex: 1;
}
.tab-swiper >>> .ivu-tabs-nav-scroll {
  width: 100%;
  height: 73px;
}
.tab-swiper >>> .ivu-tabs-nav {
  height: 73px;
  float: right;
  padding-right: 13%;
}
.tab-swiper >>> .ivu-tabs-tab {
  line-height: 54px;
  color: rgba(153, 153, 153, 1);
}
.tab-swiper >>> .ivu-tabs-ink-bar {
  background-color: #5e9f46;
}
.tab-swiper >>> .ivu-tabs-tab-active {
  color: #5e9f46;
}
.tab-swiper >>> .ivu-tabs-tab:hover {
  color: #5e9f46;
}
.tab-swiper >>> .ivu-tabs-bar {
  margin-bottom: 0px;
}
.companyName {
  position: absolute;
  height: 35px;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
  line-height: 68px;
  padding-left: 86px;
}
.flex-column-box {
  display: flex;
  flex-direction: column;
}
.scanCode {
  background-color: #fff;
  flex: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: relative;
  text-align: center;
}
.scanCode::after {
  position: absolute;
  left: 50%;
  top: 50px;
  bottom: 50px;
  width: 2px;
  content: '';
  background-image: linear-gradient(to top, #fff, #5e9f46e0, #fff);
}
.scanCode p {
  margin-top: -10px;
}
.flex-box {
  display: flex;
  justify-content: space-around;
  
}
.scandownLoad {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  padding: 10px 0;
  color: white;
  /* border: 1px solid pink; */
  text-align: center;
  background-color: #5e9f46;
}
.footer {
  width: 100%;
  height: 21px;
  background: #ffffff;
  border-radius: 0px;
  position: fixed;
  bottom: 0;
  z-index: 999;
}
.footer-text {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  color: #999999;
  border-top: 1px solid rgba(204, 204, 204, 0.3);
  opacity: 1;
}
.xiaoxinjiaServer,
.xiaoxinjiaApp {
  padding-top: 15px;
}
.xiao-Word {
  color: #5e9f46;
  font-size: 16px;
}
.bg-img {
  width: 120px;
}
a{
  color: #999;
}
</style>
