<template>
  <div style="width:100%;height:100%;">
    <home v-if="isIndex =='0'"></home>
    <serve v-if="isIndex =='1'"></serve>
    <about v-if="isIndex =='2'"></about>
    <div style="position:fixed;right: 3%;bottom: 46%">
      <ul>
        <li @click="setClick(0)">
          <img v-if="isIndex == '0'" src="./../assets/dot.png"/>
          <img v-else src="./../assets/dotActive.png"/>
        </li>
        <li @click="setClick(1)">
          <img v-if="isIndex == '1'" src="./../assets/dot.png"/>
          <img v-else src="./../assets/dotActive.png"/>
        </li>
        <li @click="setClick(2)">
          <img v-if="isIndex == '2'" src="./../assets/dot.png"/>
          <img v-else src="./../assets/dotActive.png"/>
        </li>
      </ul>
    </div>
  </div>
  
</template>
<script>
import home from "./home.vue";
import serve from "./serve.vue";
import about from "./about.vue";
export default {
  name: "swiper-view",
  components: {
    home,
    serve,
    about,
  },
  data() {
    return {
      mySwiper: null,
      isIndex:0
    };
  },
  methods: {
    setClick(val){
      this.isIndex =val;
      this.$emit('onChange',val);
    }
  },
};
</script>
<style lang="less" scpoed>
ul li{
  list-style:none;
  cursor: pointer;
}
.swiper-container {
  height: 100%;
  background-color: #f4fefb;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #f4fefb;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}
.swiper-pagination-bullet{
  opacity: 1;
  background-color: RGBA(188, 216, 180, 1)!important;
}
.swiper-pagination-bullet-active{
  background-color: RGBA(94, 159, 70, 1)!important;
}
::v-deep {
  .swiper-pagination-bullet {
    background-color: red;
  }
}
</style>
