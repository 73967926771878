<template>
  <div class="home">
    <div class="header">
      <Tabs type="card" @on-click="tabClick">
        <TabPane label="孝心家APP"></TabPane>
        <TabPane label="服务商APP"></TabPane>
        <TabPane label="孝心家平台系统"></TabPane>
        <TabPane label="居家大屏可视化"></TabPane>
      </Tabs>
    </div>
    <div class="main">
      <FilialHome v-if="isIndex == 0"></FilialHome>
      <serveBusiness v-if="isIndex == 1"></serveBusiness>
      <FilialPlatform v-if="isIndex == 2"></FilialPlatform>
      <HomeScreen v-if="isIndex == 3"></HomeScreen>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>
import FilialHome from "./FilialHome.vue";
import serveBusiness from "./serveBusiness.vue";
import FilialPlatform from "./FilialPlatform.vue";
import HomeScreen from "./HomeScreen.vue";
export default {
  components: {
    FilialHome,
    serveBusiness,
    FilialPlatform,
    HomeScreen,
  },
  data() {
    return {
      isShow: false,
      isIndex: 0,
    };
  },
  methods: {
    tabClick(val) {
      if (val == 0) {
        this.isIndex = 0;
      } else if (val == 1) {
        this.isIndex = 1;
      } else if (val == 2) {
        this.isIndex = 2;
      } else if (val == 3) {
        this.isIndex = 3;
      }
    },
  },
};
</script>
<style scoped>
.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 96%;
  padding-top: 33px;
  background-image: url(./../assets/bk1.png);
}
.guard {
  width: 27%;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 51%;
  height: 54px;
}
.header >>> .ivu-tabs {
  width: 100%;
}
.header >>> .ivu-tabs-bar {
  margin-bottom: 0;
}
.header >>> .ivu-tabs-nav-container {
  height: 54px !important;
}
.header >>> .ivu-tabs-bar {
  border-bottom: transparent;
}
.header >>> .ivu-tabs-tab {
  width: 226px;
  line-height: 48px;
  border-color: transparent !important;
  background: transparent !important;
  height: 54px !important;
  margin-right: 0px !important;
  border-radius: 128px !important;
  color: rgba(129, 204, 103, 1);
  font-size: 20px;
  transition: no !important;
  text-align: center;
  border: 0px solid transparent!important;
}
.header >>> .ivu-tabs-nav-prev{
  line-height: 54px;
}
.header >>> .ivu-tabs-nav-next{
  line-height: 54px;
}
.header >>> .ivu-tabs-tab:hover {
  color: rgba(129, 204, 103, 1);
}
.header >>> .ivu-tabs-tab-active {
  height: 54px !important;
  /* background-image: url(./../assets/tabsBk.png)!important; */
  background: linear-gradient(rgba(141, 232, 107, 1), rgba(113, 196, 82, 1)) !important;
  color: white !important;
  font-weight: bold;
  font-size: 20px;
}
.header >>> .ivu-tabs-nav {
  /* border: 1px solid pink; */
  border-radius: 128px;
  height: 54px;
  box-shadow: 0px 3px 30px rgba(94, 159, 70, 0.1);
}
.main {
  display: flex;
  margin-top: 122px;
  width: 81%;
  height: 81%;
}
.guardTotal {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
  letter-spacing: 2px;
  opacity: 1;
}
.peopleNum {
  display: flex;
  height: 100%;
  margin-top: 28px;
}
.Count {
  width: 269px;
  height: 100%;
  border: 1px solid rgba(94, 159, 70, 0.2);
  border-radius: 6px;
  line-height: 99px;
}
.Count span {
  color: #5e9f46;
  font-size: 29px;
  font-weight: bolder;
  letter-spacing: 11px;
  font-family: Futura;
}
.unit {
  width: 12px;
  height: 15px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 113px;
  color: #999999;
  letter-spacing: 2px;
  opacity: 1;
  padding-left: 12px;
}
.wordContent {
  display: flex;
  flex-direction: column;
  margin-left: 9.5%;
  width: 77%;
}
.title {
  display: flex;
  justify-content: flex-start;
  height: 64px;
  font-size: 48px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.content {
  margin-top: 24px;
  text-align: start;
  width: 829px;
  height: 101px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  color: #666666;
  opacity: 1;
}
.num {
  font-size: 60px;
  font-family: Futura;
  line-height: 102px;
  color: #5e9f46;
  opacity: 1;
}
</style>
