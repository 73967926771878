<style scoped>
.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  padding-top: 33px;
  padding: 0 10px;
  background-image: url(./../assets/bk.png);
}

.header {
  width: 100%;
}

.guard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.guardTotal {
  display: flex;
  width: 120px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
  letter-spacing: 2px;
}
.peopleNum {
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 4%;
}

.num {
  font-size: 18px;
  font-family: "Futura";
  color: #5e9f46;
  margin-right: 5px;
}
.unit {
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #999999;
  letter-spacing: 2px;
}


.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6%;
}
.div-img {
  flex: 1;
}
.div-img img {
  width: 100%;
  height: 100%;
}


.wordContent {
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  justify-content: flex-start;
  height: 18%;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5e9f46;
  opacity: 1;
}
.content {
  margin-top: 10px;
  text-align: start;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 2;
  color: #666666;
  opacity: 1;
}
</style>

<template>
  <div class="home">
    <div class="header">
      <div class="guard">
        <div class="guardTotal">守护人总数</div>
        <div class="peopleNum">
          <div class="Count">
            <div class="num">21156</div>
          </div>
          <span class="unit">人</span>
        </div>
      </div>
      <div class="guard">
        <div class="guardTotal">服务商总数</div>
        <div class="peopleNum">
          <div class="num">11556</div>
          <span class="unit">人</span>
        </div>
      </div>
      <div class="guard">
        <div class="guardTotal">安装设备总数</div>
        <div class="peopleNum">
          <div class="Count">
            <div class="num">32156</div>
          </div>
          <span class="unit">人</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="div-img">
        <img src="./../assets/picture.png" />
      </div>
      <div class="wordContent">
        <div class="title">驱动业务的智慧养老系统</div>
        <div class="content">
          <div>老人通过用户端实现足不出户享受家政、康复、生活照料、居家适老化改造等服务;而且老人家属也能通过平台与老人互动，了解老人各种情况，给以老人更多的陪伴与关爱。</div>
          <div>
            老人通过用户端实现足不出户享受家政、康复、生活照料、居家适老化改造等服务;而且老人家属也能通过平台与老人互动，了解老人各种情况，给以老人更多的陪伴与关爱。康复、生活照料、居家适老化改造等服务;而且老人家属也能通过平台与老人互动，
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./../assets/style/index.css";
export default {};
</script>
